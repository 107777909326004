<template>
    <div class="header-container">
        <div class="header">
            <div class="img-wrapper">
                <img @click="$router.push('/dashboard').catch(()=>{})" :src="require('@/assets/img/logo.png')" alt="logo">
            </div>

            <div class="link-container">
                <div v-if="!showLogout">
                    <router-link to="/dashboard">{{ $t('home') }}</router-link>
                    <router-link to="/results">{{ $t('result') }}</router-link>
                </div>

                <!-- <div v-else>
                    <router-link to="/dashboard">{{ $t('home') }}</router-link>
                    <router-link to="/how-it-works">{{ $t('how_it_works') }}</router-link>
                    <router-link to="/contact-us">{{ $t('contact_us') }}</router-link>
                </div> -->
            </div>
    
            <div class="right">
                <div class="select language">
                    <select
                        v-model="language"
                        @change="handleSetActiveLanguage($event)"
                    >
                        <option
                            :value="language.id"
                            v-for="language in languages"
                            :key="language.id"
                        >
                            {{ language.lang }}
                        </option>
                    </select>
                </div>

                <div class="button-container" v-if="!showLogout">
                    <button @click="takeTest" class="take">{{ $t('take_test') }}</button>

                    <div class="menu-icon-wrapper" @click="showMenu = !showMenu">
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>

                    <div class="user-settings" @click="show = !show">
                        <img v-if="user.profile_picture" class="profile" :src="profilePicture" alt="profile">
                        <span v-else>{{ user.name.charAt(0) }}</span>
                        
                        <div class="triangle-down"></div>
                    </div>

                    <ul class="list" v-show="show">
                        <a @click="goTo('/account')">{{ $t('account') }}</a>
                        <a @click="goTo('/how-it-works')">{{ $t('how_it_works') }}</a>
                        <a @click="goTo('/contact-us')">{{ $t('contact_us') }}</a>
                        <a @click="logout">{{ $t('logout') }}</a>
                    </ul>
                </div>

                <button v-else class="logout" @click="$emit('logout')">{{ $t('logout') }}</button>
            </div>
        </div>

        <div class="mobile-link-container" v-if="showMenu">
            <a @click="goTo('/dashboard')">{{ $t('home') }}</a>
            <a @click="goTo('/results')" class="result">{{ $t('result') }}</a>

            <a @click="goTo('/account')">{{ $t('account') }}</a>
            <a @click="goTo('/how-it-works')">{{ $t('how_it_works') }}</a>
            <a @click="goTo('/contact-us')">{{ $t('contact_us') }}</a>
            <a @click="logout">{{ $t('logout') }}</a>
            <!-- <button @click="signIn" class="sign-in">{{ $t('sign_in') }}</button> -->
        </div>
    </div>
</template>

<script>
import Cookies from 'js-cookie'
import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            show: false,
            showMenu: false,
            languages: [
                { lang: 'English', id: 'en' },
                { lang: 'Chinese', id: 'cn' }
            ],
        }
    },

    computed: {
        ...mapGetters(['isSubscribed', 'hasExistingExam']),
        ...mapFields(['user', 'language']),

        showLogout () {
            return !this.isSubscribed && !this.hasExistingExam
        },

        profilePicture () {
            return process.env.VUE_APP_API_URL + '/storage/' + this.user.profile_picture
        }
    },

    methods: {
        handleSetActiveLanguage (val) {
            let lang = val.target.value

            this.$i18n.locale = lang
			Cookies.set('locale', lang)
        },
        
        goTo (link) {
            this.show = false
            this.showMenu = false
            this.$router.push(link).catch(()=>{})
        },

        logout () {
            this.$store.dispatch('logout')
        },

        async takeTest () {
            // let res = await this.$store.dispatch('takesChecker')
            
            // if (res.data.takes < 2) {
                if (this.isSubscribed && this.user.subscription.takes > 0) {
                    this.$router.push('card-assessment').catch(()=>{})
                } else {
                    this.$store.commit('TOGGLE_BANNER', {
                        status: true,
                        message: this.$t('no_takes_left')
                    })
                    this.$router.push('packages').catch(()=>{})
                }
            // } else {
            //     this.$store.commit('TOGGLE_BANNER', {
            //         status: true,
            //         message: this.$t('reached_limit')
            //     })
            // }
        }
    },
}
</script>

<style lang="scss" scoped>
.header-container {
    padding: 10px 20px;
    position: relative;

    .header { 
        display: flex;
        margin: auto;
        align-items: center;
        max-width: 1200px;

        .img-wrapper {
            // margin-right: 95px;
            flex: 1;
    
            img {
                cursor: pointer;
                width: 100px;
                height: 50px;
            }
        }
    
        .link-container {
            display: none;
            flex: 1;

            @include desktop {
                display: flex;
            }
    
            a {
                cursor: pointer;
                font-weight: 600;
                color: #221E1F;
                margin-right: 40px;
    
                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .right {
            display: flex;

            .select {
                display: flex;
                color: #221e1f;
                font-weight: 600;
                display: flex;
                margin-right: 10px;

                &.language {
                    margin-right: 10px;

                    @include desktop {
                        margin-right: 35px;
                    }
                }

                p {
                    font-family: 'Crimson Pro', sans-serif;
                    font-size: 18px;
                }

                select {
                    cursor: pointer;
                    border: transparent;
                    background-color: transparent;
                    font-family: 'Crimson Pro', sans-serif;
                    font-size: 18px;

                    option {
                        font-size: 14px;
                    }
                }
            }

            button {
                font-weight: 600;
                font-size: 18px;
                height: 45px;
                border: 1px solid #00AEEF;

                &.take {
                    font-weight: 600;
                    font-size: 18px;
                    height: 45px;
                    border: 1px solid #00AEEF;
                    background-color: #ffffff;
                    color: #00AEEF;
                    margin-right: 15px;
                }

                &.logout {
                    width: 124px;
                    background-color: #00AEEF;
                    color: #ffffff;
                }
            }

            .button-container {
                display: flex;
                position: relative;
                align-items: center;
    
                button {
                    font-weight: 600;
                    font-size: 18px;
                    height: 45px;
                    border: 1px solid #00AEEF;
    
                    &.take {
                        font-weight: 600;
                        font-size: 16px;
                        height: 35px;
                        border: 1px solid #00AEEF;
                        background-color: #ffffff;
                        color: #00AEEF;
                        margin-right: 15px;
                        padding: 5px 10px;

                        @include desktop {
                            font-size: 18px;
                            height: 45px;
                            margin-right: 15px;
                            padding: 10px 15px;
                        }
                    }
    
                    &.logout {
                        width: 124px;
                        background-color: #00AEEF;
                        color: #ffffff;
                    }
                }

                .menu-icon-wrapper {
                    cursor: pointer;

                    @include desktop {
                        display: none;
                    }
                    
                    .line {
                        width: 20px;
                        height: 2px;
                        background-color: black;
                        margin: 4px 0;
                    }
                }
        
                .user-settings {
                    position: relative;
                    display: none;
                    align-items: center;
                    cursor: pointer;

                    @include desktop {
                        display: flex;
                    }
        
                    .triangle-down {
                        margin-left: 8px;
                        width: 0; 
                        height: 0; 
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-top: 5px solid #000000;
                    }
                    
                    .profile {
                        height: 46px;
                        width: 46px;
                        border-radius: 50%;
                        border: 1px solid #00AEEF;
                    }

                    span {
                        height: 46px;
                        width: 46px;
                        border-radius: 50%;
                        border: 1px solid #00AEEF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-transform: capitalize;
                        background-color: #00BFA5;
                        color: #ffffff;
                    }
                }
        
                .list {
                    top: 60px;
                    right: 0px;
                    z-index: 1;
                    width: 160px;
                    position: absolute;
                    border: 1px solid #D1D1D1;
                    border-radius: 4px;
                    background: #ffffff;
                    padding: 10px 0;
    
    
                    a {
                        display: block;
                        cursor: pointer;
                        padding: 8px 15px;
                        color: #A6A7AD;
                        font-size: 14px;
                        font-weight: 600;
        
                        &:hover {
                            background-color: rgba(0, 174, 239, 0.17);
                            color: #00AEEF;
                        }
                    }
        
                }
            }
        }
    
    }

    .mobile-link-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #ffffff;
        left: 0;
        padding: 10px 0 20px;
        text-align: center;
        z-index: 1;

        a {
            cursor: pointer;
            font-weight: 600;
            color: #221E1F;
            padding: 10px;

            &.result {
                border-bottom: 1px solid #00AEEF;
            }
        }

        .sign-in {
            font-weight: 600;
            font-size: 18px;
            height: 42px;
            border: 1px solid #00AEEF;
            width: 125px;
            background-color: #00AEEF;
            color: #ffffff;
            margin: auto;
        }
    }
}
</style>